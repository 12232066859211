import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import MainToolbar from "../components/main-toolbar"
import LineTitle from "../components/LineTitle"
import Typography from "@material-ui/core/Typography"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  background: {
    padding: 0,
    backgroundColor: "#202020",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
    paddingBottom: theme.spacing(5),
  },
  contactTitle: {
    color: "white",
    textTransform: "uppercase",
    paddingTop: "170px",
    ["@media (max-width:780px)"]: {
      paddingTop: "100px",
    },
  },
  contactTitle2: {
    color: "white",
    textTransform: "uppercase",
    paddingTop: "40px",
  },
}))

const ThankYou = ({ intl, location }) => {
  const classes = useStyles()
  const form = "https://kooldigital.lpages.co/test-form/"
  const t = (id) => intl.formatMessage({ id })

  return (
    <Layout>
      <SEO title="Booked" path={location.pathname} />
      <Helmet>
        {/* Event snippet for Google ads Booking Thank You conversion page */}
        <script>
          {`
            gtag('event', 'conversion', {
              'send_to': 'AW-618149115/FQ6dCKHy7cYDEPvp4KYC'
            });
          `}
        </script>
      </Helmet>
      <MainToolbar className={classes.toolbar} />
      <Container maxWidth={"xl"} className={classes.background}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Container>
              <LineTitle header="Thank you" subheader="See you soon" />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.formContainer}>
            <Typography
              component="h5"
              variant="p"
              align="center"
              className={classes.contactTitle}
            ></Typography>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default injectIntl(ThankYou)

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
